.table-demo {
  .p-datatable-frozen-tbody {
    font-weight: bold;
  }

  .p-datatable-scrollable {
    .p-frozen-column {
      font-weight: bold;
    }
  }

  .image-text {
    vertical-align: middle;
    margin-left: 0.5rem;
  }
}
