/* .p-dropdown {
  font-size: 2rem;
  color: black;
}

.p-dropdown-trigger {
  font-size: 2rem;
  color: black;
}
.p-dropdown-panel {
  font-size: 1.4rem;
  color: black;
}
.p-dropdown-items-wrapper {
  border: 2rem;
  color: black;
  font-size: 1.8rem;
} */

.p-dropdown-label {
  width: 100%;

  text-transform: uppercase;
  text-align: left;
  color: rgb(3, 17, 33) !important;
  font-size: 1.2rem !important;
}
.p-dropdown-items {
  font-size: 1.6rem;
  color: rgb(3, 17, 33) !important;
  background-color: gainsboro;
}

.p-dropdown-item {
  font-size: 1.6rem;
  color: rgb(3, 17, 33) !important;
}
.p-dropdown-items .p-dropdown-item:hover {
  font-size: 1.6rem;
  color: gainsboro !important;
  background-color: rgb(3, 17, 33) !important;
}
.p-dropdown-trigger {
  color: rgb(3, 17, 33) !important;
  font-size: 1.2rem !important;
}
.p-dropdown-open {
  font-size: 1.6rem;
  color: rgb(3, 17, 33) !important;
}
.p-dropdown-items-wrapper {
  background-color: rgb(3, 17, 33) !important;
}
/*


.p-inputtext {
  font-size: 2rem;
}
.p-inputnumber {
  font-size: 2rem;
}
.p-dropdown-placeholder {
  font-size: 2rem; 
  
} */
/* .p-menuitem-icon {
  display: none !important;
} */
.p-inputnumber-input {
  font-size: 1.6rem !important;
}
